import React from "react"
import { graphql } from "gatsby"
import { generateNameWithHonorific } from "../../lib/helpers"
import WebsiteMeta from "../../components/WebsiteMeta"
import styled from "styled-components"
import Logo from "../../components/svg/Logo"

export default function sanityArticleScreenshotTemplate({ data }) {
  const article = data.sanityArticle

  if (article.status !== "approved") {
    return null
  }

  const name = generateNameWithHonorific(
    article.author.name,
    article.author.honorific
  )

  return (
    <>
      <WebsiteMeta title={article.title} screenshot />
      <Screenshot>
        <Header>
          <Inner>
            <Title>{article.title}</Title>
          </Inner>
        </Header>
        <Footer>
          <div>
            <Author>{name}</Author>
          </div>
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
        </Footer>
      </Screenshot>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    sanityArticle(_id: { eq: $id }) {
      title
      status
      author {
        honorific
        name
      }
    }
  }
`

const Screenshot = styled.article`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 1200px;
  max-height: 630px;
  font-family: var(--font-sans-serif);

  * {
    margin: 0;
  }
`

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  padding: 3.5rem;
  background: var(--maroon);
`

const Inner = styled.div`
  display: flex;
  flex-direction: column;
`

const Title = styled.h1`
  color: var(--light100);
  font-family: var(--font-serif);
  font-size: 6em;
`

const Footer = styled.footer`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2rem 3.5rem;
  background: var(--maroon);
`

const Author = styled.p`
  color: rgba(var(--white-rgb), 0.75);
  font-size: 3em;
  font-weight: bold;
`

const LogoWrapper = styled.div`
  svg {
    fill: rgba(var(--white-rgb), 0.75);
  }
`
